import { Component } from '@angular/core';
// import { ComingSoon } from './coming-soon/coming-soon.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'residence-lipnik';
}
