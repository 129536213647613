import { Component, OnInit } from '@angular/core';
import { faFacebookF, faInstagram } from '@fortawesome//free-brands-svg-icons';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  faFacebookF = faFacebookF;
  faInstagram = faInstagram;

  constructor() { }

  ngOnInit(): void {
  }

}
