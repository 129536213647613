<div class="coming-soon">
  <div class="container">
    <div class="logo">
      <img src="./assets/images/logo.png" alt="logo">
    </div>
    <div class="title">
      <div class="title-wrapper">
        <h1>Очаквайте &#8203;</h1>
        <h1>скоро &#8203;</h1>
        <h1>нашият &#8203;</h1>
        <h1>сайт &#8203;</h1>
      </div>
    </div>
    <div class="social-btns">
      <a href="https://www.facebook.com/residence.lipnik" target="blank" class="btn facebook">
        <i class="fa fa-facebook"></i>
      </a>
      <a href="https://www.instagram.com/residence.lipnik/" target="blank" class="btn instagram">
        <i class="fa fa-instagram"></i>
      </a>
    </div>
    <div class="copyrights">
      &copy; 2021
    </div>
  </div>
  <div id='stars'></div>
  <div id='stars2'></div>
  <div id='stars3'></div>
</div>
